@font-face {
  font-family: defaultFont;
  src: url(assets/font/GraphikLCG-Bold.ttf);
  font-weight: 800;
}

@font-face {
  font-family: defaultFont;
  src: url(assets/font/GraphikLCG-Black.ttf);
  font-weight: 900;
}

@font-face {
  font-family: defaultFont;
  src: url(assets/font/GraphikLCG-Medium.ttf);
  font-weight: 700;
}

@font-face {
  font-family: defaultFont;
  src: url(assets/font/GraphikLCG-Regular.ttf);
  font-weight: 500;
}

* { 
  box-sizing: border-box;
  margin: 0;
  font-family: defaultFont !important;
  font-size: 1.4rem;
}

html {
  font-size: 62.5%;
  color: #464D60;
}

.bold {
  font-weight: 700;
}

#body {
  margin-top: 7rem;
  min-height: calc(100vh - 7rem);
  width: 100%;
}

#dashboard-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 7rem;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  /* -webkit-box-shadow: 0 .1rem .5rem 0 rgba(0;
  0, 0, 0.2);
  -moz-box-shadow: 0 .1rem .5rem 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 .1rem .5rem 0 rgba(0, 0, 0, 0.2); */
  z-index: 99999;
}

#dashboard-nav-logo-wrapper {
  height: 100%;
  padding-left: 3rem;
}

#menu-section {
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#menu-section-mobile {
  width: 100%;
  height: 90%;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-section-mobile a {
  color: white !important;
  margin: 3rem 0;
}

#menu-section a:-webkit-any-link, #menu-section-mobile a:-webkit-any-link {
  text-decoration: none;
  color: initial;
}

#language-section {
  padding-right: 3rem;
  display: flex;
  align-items: center;
}

#language-section-mobile {
  display: flex;
  align-items: center;
  color: white;
  padding: 2rem;
}

#language-section button, #language-section button span {
  border: 0;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

#hero-cover {
  width: 100%;
  height: 70rem;
  padding-top: 22rem;
  padding-left: 18rem;
}

#hero-cover-freight{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  position: absolute;
  left: 0;
  top: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

#hero-cover-content {
  width: 40%;
}

#hero-cover h1 {
  font-size: 5rem;
  font-weight: 800;
  margin-bottom: 2.4rem;
}

#hero-cover p {
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
}

#hero-cover-contact-us-btn {
  border: white .1rem solid;
  background: none;
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
  color: white;
  padding: 1rem;
  margin-left: 1.5rem;
  cursor: pointer;
}

#footer {
  width: 100%;
  min-height: 25rem;
  background-color: #57A665;
  color: white;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-section {
  width: 30%;
  color: white;
  padding: 0 1.5rem;
  white-space: pre-wrap;
}

.footer-section h2{
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 700;
}

.footer-section p {
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
}

input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #FFB206;
  width: 3rem;
  height: 3rem;
  border: .2rem solid #FFB206;
  border-radius: 50%;
  transform: translateY(-0.075rem);
}

input[type="radio"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  display: grid;
  place-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1rem 1rem var(#FFB206);
  background-color: #FFB206;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.input-container {
  border: 1px #FFB206 solid;
}

.add-shadow {
  -webkit-box-shadow: 0 .1rem .5rem 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 .1rem .5rem 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 .1rem .5rem 0 rgba(0, 0, 0, 0.2);
}

#us-money-input::placeholder {
  color: white;
}

#us-money-input::-ms-input-placeholder {
  color: white;
}

.page-title {
  color: #FFB206;
  font-weight: 800;
  font-size: 5rem;
}

.page-title-underline {
  background: #FFB206;
  width: 10rem;
  height: .2rem;
  margin-top: 4rem;
}

#about-us-wrapper {
  padding: 3rem;
  padding-top: 20rem;
  padding-bottom: 20rem;
  display: flex;
  justify-content: center;
}

.about-us-section {
  width: 30%;
  padding-right: 1.5rem;
}

#home-services-wrapper {
  width: 100%;
  height: 65rem;
  background: #FAFBFD;
  display: flex;
}

#home-services-image {
  width: 40rem;
  height: 65rem;
  background-repeat: no-repeat;
}

#home-services-body-wrapper {
  width: 100%;
  padding-left: 7rem;
  padding-top: 7rem;
}

#home-services-service-section-wrapper {
  width: 100%;
  margin-top: 2.5rem;
  display: flex;
  cursor: pointer;
}

.home-services-service-section {
  width: 30%;
  padding: 0 2rem 0 0;
}

#home-why-choose-us-wrapper {
  width: 100%;
  padding: 10rem 0 10rem 10rem;
}

#home-why-choose-us-body-wrapper {
  width: 100%;
  display: flex;
  margin-top: 5rem;
}

#home-why-choose-us-text-wrapper {
  width: 70%;
  padding: 0 7rem 3rem 0;
}

#home-vision {
  display: flex;
}

#home-mission {
  display: flex;
  margin-top: 7rem;
}

.home-why-choose-us-text-title {
  font-size: 3.4rem;
  font-weight: 700;
  color: black;
  width: 50%;
}

.home-why-choose-us-text-paragraph-wrapper {
  width: 50%;
}

#home-vision-mission-img {
  width: 30%;
}

#service-info-banner {
  padding: 8rem 0 20rem 10rem;
  display: flex;
  flex-direction: row-reverse;
}

#service-info-banner-img {
  width: 50%;
}

#service-info-banner-text {
  width: 50%;
  padding: 0 15% 5rem 0;
}

#service-info-item-sourcing {
  display: flex;
  padding-bottom: 20rem;
  flex-wrap: wrap;
}

.service-info-item-sourcing-info-section {
  width: 50%;
}

.service-info-item-sourcing-info-section-text-body {
  width: 100%;
  padding-top: 7rem;
  padding-bottom: 7rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#service-info-item-sourcing-info-section-text-title {
  width: 100%;
  display: flex;
  justify-content: center;
}

#service-info-money-changer-info-wrapper {
  display: flex;
  padding-bottom: 30rem;
}

#service-info-money-changer-info-img-wrapper {
  width: 40rem;
}

#service-info-money-changer-info-img-wrapper .image {
  background-position: center;
  height: 50%;
  width: 100%;
}

#service-info-money-changer-info-header-wrapper {
  width: 50%;
}

#service-info-money-changer-info-text-wrapper {
  width: calc(100vw - 40rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 6rem;
  flex-wrap: wrap;
}

.service-info-money-changer-info-text-info-wrapper {
  width: 50%;
  padding-right: 5rem;
}

#service-info-terms-and-conditions {
  display: flex;
  margin-bottom: 30rem;
  flex-wrap: wrap;
}

#service-info-terms-and-conditions-title-section {
  width: 40%;
  padding-left: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

#service-info-terms-and-conditions-body-section {
  width: 60%;
  padding: 15rem 2rem 2rem 2rem;
}

#service-info-terms-and-conditions-body-section #body {
  border: dashed #B5B5B5 .5rem;
  min-height: 50rem;
  min-width: 50rem;
  padding: 5rem;
}

#contact-banner {
  height: 65rem;
  background: #FAFBFD;
  overflow: hidden;
  display: flex;
}

#contact-banner-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
}

#contact-banner-title {
  font-size: 5rem;
  font-weight: 800;
  color: black;
  margin-bottom: 2rem;
}

#contact-banner-desc {
  font-size: 2.4rem;
  font-weight: 700;
  color: black;
  margin-bottom: 2rem;
}

#contact-banner-wa {
  width: 25rem;
  height: 5rem;
  color: white;
  font-weight: 800;
  border: 0;
  background: #57A665;
  cursor: pointer;
}

#contact-text {
  padding-top: 20rem;
  padding-bottom: 20rem;
  display: flex;
  justify-content: center;
}

#contact-text-info {
  margin-left: 5rem;
}

.freight-service-title {
  color: black;
  font-weight: 700;
}

#cbm-input-wrappper {
  display: flex;
  flex-direction: column;
}

#cbm-input {
  margin-top: 1rem;
  width: 66.7rem;
  height: 5rem;
  border: 0;
  text-align: center;
  box-shadow: 4px 4px 10px rgba(255, 178, 6, 0.2);
}

#freight-service-price-calculation-body {
  margin-left: 14.5%;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
}

#freight-service #page-body {
  padding: 5rem;
}

.freight-service-price-calculation-section {
  margin-left: 5%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
  border-bottom: dashed #FFB206 .1rem;
}

#mode-of-transport-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: dashed #FFB206 .1rem;
}

#freight-service-grand-total-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
  border-bottom: dashed #FFB206 .1rem;
}

#freight-service-grand-total-section #calculate-button {
  margin-top: 3rem;
  width: 80%;
  height: 5rem;
  color: white;
  border: 0;
  background: #4ABCF5;
  font-weight: 700;
}

#freight-service-grand-total-section #grand-total {
  width: 80%;
  margin-top: 2rem;
  background: #E5E5E5;
  height: 9.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10% 0 10%;
}

#freight-service-grand-total-section #grand-total #total-text {
  font-size: 2.2rem;
  color: black;
  font-weight: 700;
}

#freight-service-grand-total-section #grand-total #grand-total-number {
  font-size: 2.2rem;
  color: black;
  font-weight: 800;
}

#freight-service-contact-us {
  width: 100%;
  display: flex;
  padding-top: 3rem;
  align-items: center;
}

#freight-service-contact-us-btn {
  color: white;
  font-size: 1.6rem;
  font-weight: 800;
  width: 14.5rem;
  height: 3.5rem;
  background: #4ABCF5;
  border: 0;
  margin-left: 2rem;
}

#item-sourcing-container {
  width: 100%;
  /* height: 63rem; */
  padding-top: 3rem;
  padding-right: 8%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

#item-sourcing-banner {
  position: absolute;
  left: 10%;
  width: 86.3rem;
}

#item-sourcing-banner-img {
  width: 100%;
  height: 63rem;
  background-position: bottom left;
  z-index: 1;
}

#item-sourcing-texts {
  width: 40rem;
  z-index: 999;
}

#money-changer-body {
  width: 100%;
  display: flex;
  padding: 7rem 0 7rem 0;
  align-items: center;
}

#money-changer-body-text {
  width: 35%;
  display:flex;
  justify-content: flex-end;
  padding-right: 3rem;
}

#money-changer-body-changer {
  width: 65%;
  display:flex;
  padding-left: 3rem;
}

#money-changer-body-changer #money-changer {
  padding: 0 1rem 0 1rem;
  height: 20rem;
  /* width: 73rem; */
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#money-changer-body-changer #exchange-ic {
  height: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

#money-changer-contact-us {
  width: 100%;
  padding-left: 15rem;
  display: flex;
  align-items: center;
  padding-bottom: 8rem;
}

@media only screen and (min-width: 900px) {
  
  .show-desktop {
    display: inherit;
  }

  .show-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 900px) {

  #hero-cover {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #hero-cover-content {
    width: 100%;
  }
  
  .show-desktop {
    display: none !important;
  }

  .show-mobile {
    display: initial;
  }

  #footer {
    justify-content: center;
    padding: 0 2rem;
    padding-top: 3rem;
  }

  .footer-section {
    width: 100%;
    margin-bottom: 3rem;
  }

  .page-title {
    font-size: 3.2rem;
  }

  .page-title-underline {
    margin-top: 2rem;
  }

  #about-us-wrapper {
    padding: 2rem;
    flex-direction: column;
    align-items: center;
  }

  .about-us-section {
    width: 100%;
  }

  #home-services-wrapper, #home-why-choose-us-body-wrapper {
    height: unset;
    flex-direction: column;
    align-items: center;
  }

  #home-services-image {
    width: 100%;
    height: 19rem;
    background-position: center;
    background-size: cover;
  }

  #home-services-service-section-wrapper {
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }

  .home-services-service-section {
    width: 100%;
    margin-bottom: 2rem;
  }

  #home-services-body-wrapper {
    padding: 2rem;
  }
  #home-why-choose-us-wrapper {
    padding: 2rem 0;
  }

  #home-why-choose-us-text-wrapper {
    padding: 2rem;
    width: 100%;
  }

  #home-why-choose-us-title {
    padding: 2rem;
  }

  #home-vision, #home-mission {
    flex-direction: column;
  }

  .home-why-choose-us-text-title {
    width: 100%;
    margin-bottom: 2rem;
  }

  .home-why-choose-us-text-paragraph-wrapper {
    width: 100%;
  }

  #home-vision-mission-img, #service-info-banner-img {
    width: 100%;
    height: 20rem;
    background-size: cover;
  }

  #service-info-banner {
    flex-direction: column;
    padding: 0;
  }

  #service-info-banner-text {
    width: 100%;
    padding: 2rem 3rem;
  }

  #service-info-item-sourcing {
    padding-bottom: 0;
  }

  .service-info-item-sourcing-info-section.text {
    width: 100%;
  }

  #service-info-item-sourcing-info-section-text-title {
    justify-content: flex-start;
  }
  
  .service-info-item-sourcing-info-section-text-body {
    justify-content: flex-start;
    padding: 2rem 3rem;
  }

  #service-info-money-changer-info-wrapper {
    flex-direction: column;
    padding-bottom: 7rem;
  }

  #service-info-money-changer-info-img-wrapper {
    width: 100%;
    display: flex;
    height: 30rem;
  }
  
  #service-info-money-changer-info-img-wrapper .image {
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
  }

  #service-info-money-changer-info-header-wrapper {
    width: 100%;
  }
  
  #service-info-money-changer-info-text-wrapper {
    width: 100%;
    padding: 2rem 3rem;
  }
  
  #service-info-money-changer-info-body-wrapper {
    flex-direction: column;
  }

  .service-info-money-changer-info-text-info-wrapper {
    width: 100%;
  }

  #service-info-terms-and-conditions {
    margin-bottom: 0;
  }
  
  #service-info-terms-and-conditions-title-section {
    width: 100%;
    padding: 0;
    align-items: flex-start;
    padding: 2rem 3rem;
  }

  #service-info-terms-and-conditions-body-section {
    width: 100%;
    padding: 2rem 3rem;
  }

  #service-info-terms-and-conditions-body-section #body {
    min-width: unset;
  }

  #contact-banner {
    display: flex;
    justify-content: center;
    position: relative;
  }

  #contact-banner-img {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  #contact-text {
    flex-direction: column;
    padding: 2rem;
  }

  #contact-text-info {
    margin-left: 0;
    margin-top: 2rem;
  }

  #cbm-input-wrappper {
    width: 30rem;
  }
  
  #cbm-input {
    width: 100%;
  }

  .freight-service-title {
    margin-bottom: 2rem;
    text-align: center;
    width: 100%;
  }

  #freight-service-price-calculation-body {
    padding: 2rem 0;
    width: 100%;
    margin: 0;
  }

  #freight-service #page-body {
    padding: 2rem;
  }

  .freight-service-price-calculation-section {
    margin: 0;
  }

  #mode-of-transport-section {
    flex-direction: column;
    align-items: flex-start;
  }

  #freight-service-grand-total-section #calculate-button, #freight-service-grand-total-section #grand-total {
    width: 100%;
  }

  #freight-service-grand-total-section #grand-total {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  #freight-service-grand-total-section #grand-total #total-text {
    width: 100%;
    font-size: 1.6rem;
  }

  #freight-service-grand-total-section #grand-total #grand-total-number {
    font-size: 1.6rem;
  }

  #freight-service-contact-us {
    flex-direction: column;
    align-items: flex-start;
  }

  #freight-service-contact-us-btn {
    margin-top: 2rem;
    margin-left: 0;
  }

  #item-sourcing-container {
    flex-direction: column;
    padding: 0;
  }

  #item-sourcing-banner {
    position: relative;
    left: unset;
    /* right: 0; */
    width: 100%;
    padding-left: 5rem;
    overflow: hidden;
  }

  #item-sourcing-banner-img {
    background-position: center bottom;
  }

  #item-sourcing-texts {
    width: 100%;
    padding: 3rem;
  }

  #money-changer-body {
    flex-direction: column;
  }

  #money-changer-body-text, #money-changer-body-changer {
    width: 100%;
    padding: 0 3rem;
  }

  #money-changer-body-text {
    justify-content: flex-start;
  }

  #money-changer-body-changer #money-changer {
    width: 100%;
    height: unset;
    padding: 3rem 0;
    margin-top: 2rem;
    flex-wrap: wrap;
  }
  
  #money-changer-body-changer #exchange-ic {
    width: 100%;
    height: unset;
    display: flex;
    justify-content: center;
    padding: 2rem 0;
  }

  #money-changer-contact-us {
    /* margin-left: 0; */
    padding: 3rem;
  }

  .floating{
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    background-color:#0C9;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    box-shadow: 2px 2px 3px #999;
  }
  
  .my-float{
    margin-top:22px;
  }

}
